var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editable-cell" }, [
    _vm.editable
      ? _c(
          "div",
          { staticClass: "editable-cell-input-wrapper" },
          [
            _c("a-input", {
              attrs: { value: _vm.value },
              on: { change: _vm.handleChange, pressEnter: _vm.check }
            }),
            _c("a-icon", {
              staticClass: "editable-cell-icon-check",
              attrs: { type: "check" },
              on: { click: _vm.check }
            })
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "editable-cell-text-wrapper" },
          [
            _vm._v("\n        " + _vm._s(_vm.value || " ") + "\n        "),
            _c("a-icon", {
              staticClass: "editable-cell-icon",
              attrs: { type: "edit" },
              on: { click: _vm.edit }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }