export default {
  // USER CONCERNED MESSAGES
  MESSAGE_SENT: "Votre email a été envoyé",
  MESSAGE_SENT_FAIL: "Une erreur est survenue lors de l'envoie",
  ERROR_HAPENNED: "Une erreur est survenue ",
  OPERATION: "Opération",
  CHOISIR_TYPE: "Choisir un type",
  ANNUAL_PAIEMENT: "Vous devez payer votre cotisation annuelle",
  ANNUAL_PAIEMENT_ERROR1:
    "Vous pouvez payer selon les modes de paiement suivants :",
  ANNUAL_PAIEMENT_ERROR2: " - Par chèque",
  ANNUAL_PAIEMENT_ERROR3: " - En espèces au niveau du bureau du conseil",
  ANNUAL_PAIEMENT_ERROR4: " - Par versement au compte :  ",
  ANNUAL_PAIEMENT_ERROR5: "BNA 527 020 000 9239/58",
  ANNUAL_PAIEMENT_ERROR6: "En ligne",
  UPLOAD_SUCCESS: "Importation avec succès",
  UPLOAD_FAIL: "Importation a échouée",
  UPLOAD_SUCCESS_FICHIER: " a été importé avec succès ",
  ACCEPT_TERMS: "Vous devez accepter les conditions d'inscription",
  DOSSIER_SOUMIS_SUCCESS: "Votre dossier a été soumis",
  DOSSIER_SAVE_SUCCESS: "Vos modifications ont bien été enregistrées",
  PDF_FILE: "application/pdf",
  PNG_FILE: "image/jpeg",
  JPEG_FILE: "image/png",
  IMPORTATION_ERROR_TYPE:
    "Vous pouvez uniquement Joindre des fichiers pdf et les images!",
  SUPPRIMER_LIGNE: "Etes-vous sûr de vouloir supprimer cette ligne ?",
  VALIDER_LIGNE:
    "Voulez-vous valider et notifier cette demande d'attestation ?",
  ADHERENT_PAS_PAYER: "L'adhérent n'a pas encore payé la cotisation annuelle !",
  DEVALIDER_ADHERENT: "Voulez-vous dévalider cette demande d'attestation ?",
  DONNES_SAUVGARDER: "Les données ont bien été sauvegardés",

  //DEVELOPPING CONST
  BOITE_RECEPTION: 1,
  MESSAGE_ENVOYE: 2,
};
